import { render, staticRenderFns } from "./ForgotPasswordModal.vue?vue&type=template&id=275a4832&scoped=true"
import script from "./ForgotPasswordModal.vue?vue&type=script&lang=js"
export * from "./ForgotPasswordModal.vue?vue&type=script&lang=js"
import style0 from "./ForgotPasswordModal.vue?vue&type=style&index=0&id=275a4832&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "275a4832",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControllerForgotPassword: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerForgotPassword.js').default})
