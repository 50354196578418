import { render, staticRenderFns } from "./ProfileView.vue?vue&type=template&id=99327cd4&scoped=true"
import script from "./ProfileView.vue?vue&type=script&lang=js"
export * from "./ProfileView.vue?vue&type=script&lang=js"
import style0 from "./ProfileView.vue?vue&type=style&index=0&id=99327cd4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99327cd4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControllerAddress: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerAddress.js').default,ControllerChangePassword: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerChangePassword.js').default,ControllerWishlist: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerWishlist.js').default,ControllerProduct: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerProduct.js').default,ControllerProductList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductList.js').default,ControllerOrderHistory: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerOrderHistory.js').default})
