import { render, staticRenderFns } from "./CheckoutView.vue?vue&type=template&id=2257cf4a&scoped=true"
import script from "./CheckoutView.vue?vue&type=script&lang=js"
export * from "./CheckoutView.vue?vue&type=script&lang=js"
import style0 from "./CheckoutView.vue?vue&type=style&index=0&id=2257cf4a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2257cf4a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControllerCart: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerCart.js').default,ControllerAddress: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerAddress.js').default,ControllerShipping: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerShipping.js').default,ControllerPayment: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerPayment.js').default})
