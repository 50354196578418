import { render, staticRenderFns } from "./CartView.vue?vue&type=template&id=ad235bec&scoped=true"
import script from "./CartView.vue?vue&type=script&lang=js"
export * from "./CartView.vue?vue&type=script&lang=js"
import style0 from "./CartView.vue?vue&type=style&index=0&id=ad235bec&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad235bec",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControllerCart: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerCart.js').default,ControllerNewsletter: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerNewsletter.js').default})
