import { render, staticRenderFns } from "./HeaderElement.vue?vue&type=template&id=49b84e0b&scoped=true"
import script from "./HeaderElement.vue?vue&type=script&lang=js"
export * from "./HeaderElement.vue?vue&type=script&lang=js"
import style0 from "./HeaderElement.vue?vue&type=style&index=0&id=49b84e0b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49b84e0b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EditorTipTap: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js').default,EditorLink: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorLink.js').default,EditorImage: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorImage.js').default,ControllerSearch: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerSearch.js').default,ControllerWishlist: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerWishlist.js').default,ControllerCart: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerCart.js').default,EditorList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorList.js').default,ControllerNavigationItem: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerNavigationItem.js').default,ControllerNavigationBar: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerNavigationBar.js').default,ControllerHeader: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerHeader.js').default})
